







































































































































































import {Component, Emit, Vue, Watch} from "vue-property-decorator";
import CampiService, {CampiResponse, CampoResponse} from "@/services/CampiService";

import Toolbar from "@/components/Toolbar.vue";
import EditMediaField from "@/components/EditMediaField.vue";
import StruttureService, {StruttureResponse} from "@/services/StruttureService";
import EditCampo from "@/components/editCampo.vue";

@Component({
  components: {
    EditCampo,
    Toolbar,
    EditMediaField
  }
})
export default class Fields extends Vue{
  //Data
  public numeroCampi:number =  10;
  public prenotazioni:number =  55;
  public campoSelezionato :number = -1;
  public dialogVisible:boolean = false;
  public dialogVisibleEditField:boolean = false;
  public datiStruttura: StruttureResponse = []
  public campi:CampiResponse = [];
  public campo:CampiResponse | null = null;
  public id_struttura:number = -1;
 //method
  public async RiceviStrutture(){
    try{
      const response = await StruttureService.getStrutture();

      if(response.error) {
        this.$message.error(response.msg)
      } else{
        this.datiStruttura = response.data;
        this.id_struttura = 0;
        this.RiceviListaCampi();
      }
    }
    catch (err:any)
    {
      this.$router.push("/error")
    }
  }

  public async RiceviListaCampi() {
    try {
      const response = await CampiService.ListaCampi(this.datiStruttura[this.id_struttura].struttura.value);
      console.log("RESPONSE", response)
      if (response.error)
        this.$message.error(response.msg);
      else {
        this.campi = response.data;
        console.log(this.campi)
        this.numeroCampi = response.data.length;
      }
    }
    catch (err:any)
    {

    }
    finally {
    }
   }
  public handleClose(): void {
    this.$confirm('Are you sure to close this dialog?')
        .then(_ => {
          this.numeroCampi = 11;
        }).catch(_ => {});
  }
  public editFieldDisponibilita(row:CampoResponse): void {
    this.$router.push("/EditField/"+this.datiStruttura[this.id_struttura].struttura.info.id+"/"+ row.id);
  }
  public async addCampo(): Promise<void> {
    const bool = await CampiService.controlloCampi(this.datiStruttura[this.id_struttura].struttura.info.id)
    if(bool.error)
      this.$message.error(bool.msg)
    else if(!bool.data)
      this.$message.error("Hai raggiunto il numero massimo di campi disponibili")
    else
      await this.$router.push("/AddField/"+this.datiStruttura[this.id_struttura].struttura.info.id);
  }
  public editField(row:CampoResponse):void{
    this.dialogVisibleEditField = true;
      this.campoSelezionato = row.id;
  }
  public deleteField(row:CampoResponse):void{

    this.$confirm('Continuando il campo con tutte le sue disponibilità verra eliminato, continuare?')
    .then(async _ => {
     const response = await CampiService.eliminaCampo(this.datiStruttura[this.id_struttura].struttura.info.id,row.id)
      if(response.error)
        this.$message.error(response.msg)
      else
        this.$message.success(response.data)
      await this.RiceviListaCampi();

    })
    .catch(_ => {});
  }
  public mediaField(row:CampoResponse):void{
    console.log(row)
    this.campoSelezionato= row.id;
    this.dialogVisible=true;

  }

  public bloccaCampo(campo: any){
    if(campo.aperto){
        this.$confirm("Procedendo impedirai agli utenti di visualizzare il campo", "Sei sicuri di voler disabilitare il campo ?", {
          confirmButtonText: "Conferma",
          cancelButtonText: "Indietro"
        }).then(async () => {
          const response = await CampiService.bloccaCampo(campo.id, this.datiStruttura[this.id_struttura].struttura.value)
          if(response.error){
            this.$message.error(response.msg)
          } else {
            this.RiceviListaCampi()
          }
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Operazione annullata'
          })
        })
    } else {
      this.$confirm("Procedendo permetterai agli utenti di visualizzare il campo", "Sei sicuri di voler sbloccare il campo ?", {
        confirmButtonText: "Conferma",
        cancelButtonText: "Indietro"
      }).then(async () => {
        const response = await CampiService.bloccaCampo(campo.id, this.datiStruttura[this.id_struttura].struttura.value)
        if(response.error){
          this.$message.error(response.msg)
        } else {
          this.RiceviListaCampi()
        }
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Operazione annullata'
        })
      })
    }
  }

  //watch
  @Watch('numeroCampi')
  nameChanged(newVal: number) {
    this.numeroCampi = newVal
  }
  //Emit
  chiudiDaialogEdit(n:boolean){

    this.dialogVisibleEditField = n;
  }
  chiudiDaialogMedia(n:boolean){
    console.log("qui?")
    this.dialogVisible = n;
  }
  @Emit()
  addToCount(n: number) {
  }
  //mounted
  beforeUpdate(){
  }
  mounted() {
    this.RiceviStrutture();
    this.campoSelezionato=-1;
  }
}
