
























import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import CampiService from "@/services/CampiService";

@Component
export default class EditMediaField extends Vue {
  @Prop() readonly struttura!: any
  @Prop() campo!: any
  public header:any={
    'Accept-Version': '1.0.0',
    'access_token':sessionStorage.getItem('access_token')
  }
  public fileList:any = [];

  public dialogImageUrl:string = ''
  public dialogVisible:boolean = false

  public action:string = process.env.VUE_APP_URL_BACKEND+"operatore/campi/upload/"+this.campo
  public beforeRemove(file:any, fileList:any) {
    return  this.$confirm('Vuoi rimuore questa immagine?')
    .then(_ => {
      this.fileList = fileList;
    });
  }
  handlePictureCardPreview(file:any) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }
  public handlePreview(file:any) {
  }
  @Watch('campo')
  async campoSelezionatoWatch(newVal: number) {
    try {
      if( this.campo != -1) {
        this.fileList = [];
        const response = await CampiService.campo(this.struttura, this.campo);
        if (response.error) {
          this.$message.error(response.msg);
          this.chiudiDaialog()
        } else {
          for (let image of response.data[0].media)
            this.fileList.push({
              url: image
            })
        }
      }
    }
    catch (err:any)
    {

    }
  }
  @Emit()
  chiudiDaialog(){
    console.log("entro?")
    this.$emit("chiudiDaialog",false)
  }
  mounted(){
    this.campoSelezionatoWatch(-1)
  }
}
