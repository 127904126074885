











































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
  import {Loading} from "element-ui";
  import CampiService, {CampoRequest} from "@/services/CampiService";
  import {CampoClass, checkCampoRules, checkSport} from "@/Classes/CampoClass";
import editServiziCampo from "@/components/editServiziCampo.vue";
@Component({
  components: {
    editServiziCampo
  }
})
export default class EditCampo extends Vue {
  public form: CampoClass = new CampoClass();
  @Prop() id_struttura!: number
  @Prop() id_campo!: number
  @Prop() flag_popUp!: number
  public boolCampo:boolean =false;
  public listaSuperfici:any = [];
  public serviziSelezionati:number[] =[];
  public startTime: any = null;
  public step1:boolean=false;
  public sport: checkSport[] = [];
  public rule: checkCampoRules = {
    nome: [{required: true, message: 'Nome del campo obbligatorio', trigger: 'blur'}],
    descrizione: [{required: true, message: 'Descrizione obbligatoria', trigger: 'blur'}],
    tipo_superfice: [{required: true, message: 'specificare la superficie del campo', trigger: 'blur'}]
  }

  SettaServizi(n: any) {
    const temp = n.filter((x:{flag:boolean}) => {
      return x.flag
    });
    this.serviziSelezionati = temp.map((x:{id:number}) => {
      return x.id
    });
  }
  //method
  public getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  public async ModificaCampo() {
    const campiForm = this.$refs.form as any;
    campiForm.validate(async (valid: boolean) => {
      if (valid) {
        let loadingInstance1 = Loading.service({ fullscreen: true });
        const campo: CampoRequest = {
          nome: this.form.nome,
          media:[],
          sport:[],
          flex:this.form.flex,
          disponibilita:[],
          descrizione: this.form.descrizione,
          tipo_superfice: this.form.tipo_superfice,
          color:this.form.color,
          servizi_campo: this.serviziSelezionati
        }
        if(!this.form.color)
          campo.color =this.getRandomColor();

        const response = await CampiService.modificaCampo(this.id_struttura,this.id_campo, campo);
        loadingInstance1.close()
        if(!response.error){
          this.$message({
            message: response.data,
            type: 'success'
          });
          this.chiudiDaialog()
        }
        else {
          this.$message.error(response.msg);
        }
      }
    });
  }
  @Watch('id_campo')
  async campoSelezionatoWatch(newVal: number) {
    if( this.id_campo != -1){
      console.log(this.id_struttura,this.id_campo)
      const response = await CampiService.campo(this.id_struttura,this.id_campo);
      if (response.error){
        this.$message.error(response.msg);
        this.chiudiDaialog();
      }
      else{
        const data = response.data[0];
        this.form.nome = data.nome;
        this.form.descrizione = data.descrizione;
        this.form.tipo_superfice = data.terreno.id;
        this.form.media = data.media;
        this.form.flex = data.flex;
        this.form.color = data.color;
        this.form.servizi_campo = data.servizi_campo;
        this.boolCampo=true
      }
    }
  }
  @Emit()
  chiudiDaialog(){
    this.$emit("chiudiDaialog",false)
  }
  async mounted(){
    const responseTipiSuperfice = await CampiService.tipoSuperfici();
    this.listaSuperfici = responseTipiSuperfice.data.map(x => {
      return {
        label: x.nome,
        value: x.id
      }
    });
    this.campoSelezionatoWatch(-1)
  }
}
